import React, { Component } from 'react';
import { useMap, MapContainer, TileLayer, Pane, Polyline, Marker, Tooltip } from 'react-leaflet';
import { lineInfoStopIcon, lineInfoSelectedStopIcon } from '../leaflet/StopIcons';
import 'leaflet/dist/leaflet.css';

export class LineInfoMap extends Component {

    //HELPER FUNCTIONS

    getBounds(trip) {
        if (undefined === trip) {
            return [{ lat: 41.1483096, lng: -8.6108148 }, { lat: 41.1442942, lng: -8.6105935 }];
        }

        return [{ lat: trip.maxLatitude, lng: trip.maxLongitude }, { lat: trip.minLatitude, lng: trip.minLongitude }];
    }

    /*This is used to change the Tooltip permanent prop, which is immutable and can't be changed after creating the Tooltip.
    * But since this is used in the key prop, it will trigger the re-render and subsquently change the permanent prop value.
    */
    isPermanent(tripPassing) {
        const { selectedTripPassing } = this.props;
        return selectedTripPassing !== undefined && selectedTripPassing.stopId === tripPassing.stopId
    }

    //------------------------

    renderPath(trip) {
        if (undefined === trip || undefined === trip.passings) {
            return null;
        }

        const positions = [];
        trip.passings.forEach(passing => positions.push([passing.x, passing.y]));

        return (
            <div key="polyLine-line-info">
                <Polyline positions={positions} color="#f26839" />
            </div>
        );
    }

    renderPathStops(trip) {
        if (undefined === trip || undefined === trip.passings) {
            return null;
        }

        const { passing } = this.props;

        return (
            <div>
                {
                    trip.passings.map((tripPassing, idx) =>
                        <Marker
                            key={`marker-${idx}`}
                            position={[tripPassing.x, tripPassing.y]}
                            icon={passing.stopId === tripPassing.stopId ? lineInfoSelectedStopIcon : lineInfoStopIcon}>
                            {this.renderTooltip(tripPassing)}
                        </Marker>
                    )
                }
            </div>
        );
    }

    renderTooltip(tripPassing) {
        const { makeTooltipsPermanent } = this.props;

        if (makeTooltipsPermanent) {
            return (
                <Tooltip key={`tooltip-${tripPassing.order}-${this.isPermanent(tripPassing)}`} permanent={this.isPermanent(tripPassing)}>
                    <span><b>{tripPassing.name}</b> ({tripPassing.stopCode})</span>
                </Tooltip>
            );
        }

        return (
            <Tooltip key={`tooltip-${tripPassing.order}`}>
                <span><b>{tripPassing.name}</b> ({tripPassing.stopCode})</span>
            </Tooltip>
        );
    }

    render() {
        const { trip } = this.props;
        return (
            <MapContainer className="map-container" bounds={this.getBounds(trip)} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                <SetBounds bounds={this.getBounds(trip)} />
                <Pane>
                    {this.renderPath(trip)}
                </Pane>
                <Pane>
                    {this.renderPathStops(trip)}
                </Pane>
            </MapContainer>
        );
    }
}

function SetBounds({ bounds }) {
    const map = useMap();
    map.fitBounds(bounds);
    return null;
}
