import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { ProviderCommand } from '../commands/ProviderCommand';
import { ScheduleCommand } from '../commands/ScheduleCommand';
import { TripCommand } from '../commands/TripCommand';

export class Schedules extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "schedules";

        this.state = {
            commands: {
                provider: new ProviderCommand(),
                schedule: new ScheduleCommand(),
                trip: new TripCommand()
            },
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            providers: [],
            selectedProvider: undefined,
            lines: [],
            selectedLine: undefined,
            selectedDirection: undefined,
            dayTypes: [],
            selectedDaytype: undefined,
            displayLineDropdown: false,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
            isLoadingInfo: false,
        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getProviders();
        } else {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;

        setState(this.stateKey, this.state);
    }

    //DATA
    getProviders() {
        const { commands } = this.state;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));
    }

    providersSuccessCallback(result) {
        this.setState({
            providers: result
        });
    }

    getProviderLines(provider) {
        const { commands } = this.state;
        const providerName = undefined !== provider.name ? provider.name : "";
        commands.provider.getProviderLines(providerName, (r) => this.providerLinesSuccessCallback(r))
    }

    providerLinesSuccessCallback(result) {
        this.setState({
            lines: result,
            displayLineDropdown: true,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
        });
    }

    getDaytypes() {
        const { selectedLine, commands } = this.state;
        commands.schedule.getScheduleByLineGroupByDayType(selectedLine.id, (r) => this.dayTypesSuccessCallback(r));
    }

    dayTypesSuccessCallback(result) {
        this.setState({
            dayTypes: result,
            displayDaytypeDropdown: true
        });
    }

    getTrips() {
        const { commands, selectedLine, selectedDirection, selectedDaytype } = this.state;
        commands.trip.getTripsByLineAndDayType(
            selectedLine.id, selectedDirection, selectedDaytype.schedules.join(), (r) => this.getTripsSuccessCallback(r));
    }

    getTripsSuccessCallback(result) {
        this.setState({
            scheduleInfo: result,
            isLoadingInfo: false
        });
    }

    //---------

    //FUNCTIONS

    setProvider(provider) {
        this.setState({
            selectedProvider: provider,
            selectedLine: undefined,
            selectedDirection: undefined,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayLineDropdown: false,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false
        }, () => {
            this.getProviderLines(provider);
        });
    }

    setLine(line) {
        this.setState({
            selectedLine: line,
            selectedDirection: undefined,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayDirectionDropdown: true,
            displayDaytypeDropdown: false
        });
    }

    setDirection(direction) {
        this.setState({
            selectedDirection: direction,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayDaytypeDropdown: false
        }, () => {
            this.getDaytypes();
        });
    }

    determineDirectionString(direction) {
        switch (direction) {
            case "G":
                return strings.directionGo;
            case "R":
                return strings.directionReturn;
            case "C":
                return strings.directionCircular;
            default:
                return "";
        }
    }

    setDaytype(daytype) {
        this.setState({
            selectedDaytype: daytype,
            isLoadingInfo: true,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
        }, () => {
            this.getTrips();
        });
    }
    //---------------

    //RENDER

    renderDropdowns() {
        return (
            <div>
                {this.renderProviderDropdown()}
                {this.renderLinesDropdown()}
                {this.renderDirectionsDropdown()}
                {this.renderDaytypesDropdown()}
            </div>
        );
    }

    renderProviderDropdown() {
        const { providers, selectedProvider } = this.state;

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-provider-button" id="providerDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedProvider !== undefined ?
                            <div className="overflow-x-clip">
                                {this.renderProviderLogo(selectedProvider.name)}
                                &nbsp;
                                <span className="schedules-provider-dropdown-list-option">{selectedProvider.name}</span>
                            </div>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.provider}</span>
                    }
                </Button>

                <ul className="dropdown-menu schedules-provider-dropdown-list" aria-labelledby="providerDropdown">
                    {
                        providers.map(provider =>
                            <li key={provider.name} onClick={() => this.setProvider(provider)}>
                                <div className="dropdown-item">
                                    {this.renderProviderLogo(provider.name)}
                                    &nbsp;
                                    <span className="schedules-provider-dropdown-list-option">{provider.name}</span>
                                </div>
                            </li>
                        )
                    }
                </ul>

            </div>
        );
    }

    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img height="20px" src={imgUrl} alt={`${provider}`} />
        );
    }

    renderLinesDropdown() {
        const { lines, selectedLine, displayLineDropdown } = this.state;

        if (!displayLineDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-line-button" id="lineDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedLine !== undefined ?
                            <span className="overflow-x-clip">
                                <b>{selectedLine.code}</b>
                                &nbsp;
                                {selectedLine.goName}
                            </span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.line}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-line-dropdown-list" aria-labelledby="lineDropdown">
                    {
                        lines.map(line =>
                            <li key={line.goName} onClick={() => this.setLine(line)}>
                                <span className="dropdown-item">
                                    <b>{line.code}</b>
                                    &nbsp;
                                    {line.goName}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderDirectionsDropdown() {
        const { selectedLine, selectedDirection, displayDirectionDropdown } = this.state;

        if (!displayDirectionDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-direction-button" id="directionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedDirection !== undefined ?
                            <span className="overflow-x-clip">{this.determineDirectionString(selectedDirection)}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.direction}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-direction-dropdown-list" aria-labelledby="directionDropdown">
                    {
                        undefined !== selectedLine.directions ?
                            selectedLine.directions.map(direction =>
                                <li key={direction} onClick={() => this.setDirection(direction)}>
                                    <span className="dropdown-item">
                                        {this.determineDirectionString(direction)}
                                    </span>
                                </li>
                            )
                            :
                            null
                    }
                </ul>
            </div>
        );
    }

    renderDaytypesDropdown() {
        const { displayDaytypeDropdown, dayTypes, selectedDaytype } = this.state;

        if (!displayDaytypeDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-daytype-button" id="daytypeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedDaytype !== undefined ?
                            <span className="overflow-x-clip">{selectedDaytype.name}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.daytype}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-daytype-dropdown-list" aria-labelledby="daytypeDropdown">
                    {
                        dayTypes.map(daytype =>
                            <li key={daytype.name} onClick={() => this.setDaytype(daytype)}>
                                <span className="dropdown-item">
                                    {daytype.name}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderScheduleInfo() {
        const { isLoadingInfo, scheduleInfo } = this.state;

        if (isLoadingInfo) {
            return (
                <div className="width-100 text-align-center">
                    <Spinner animation="border" role="status" />
                </div>
            );
        }

        return (
            <div className="schedules-schedule-info">
                <div className="schedules-schedule-trip-info">
                    {this.renderStopsList(scheduleInfo)}
                    {this.renderTripList(scheduleInfo)}
                </div>

                {
                    0 !== scheduleInfo.exceptions.length ?
                        <div className="schedules-schedule-exception-info">
                            &nbsp;
                            {this.renderExceptionList(scheduleInfo)}
                        </div>
                        :
                        null
                }
            </div>
        );
    }

    renderStopsList(scheduleInfo) {
        return (
            <div className="schedules-schedule-stop-info">
                {
                    scheduleInfo.stops.map(stop =>
                        <div key={stop.name} title={stop.name}>
                            {stop.name}
                        </div>
                    )
                }
            </div>
        );
    }

    renderTripList(scheduleInfo) {
        return (
            <div className="schedules-schedule-passing-info">
                {
                    scheduleInfo.trips.map((trip, index) =>
                        <div key={`${trip.stopId}-${index}`} className="schedules-schedule-passing-info-list">
                            {this.renderTripPassings(trip, scheduleInfo.stops)}
                        </div>
                    )
                }
            </div>
        );
    }

    renderTripPassings(trip, stops) {
        return stops.map(stop => {
            const passing = trip.find(passing => passing.stopId === stop.id);
            if (undefined === passing) {
                return (
                    <div key={`undefined-time-${trip.order}-${stop.id}`} className="schedules-trip-passing-list-value">
                        --:--
                    </div>
                );
            }

            return (
                <div key={passing.time} className="schedules-trip-passing-list-value">
                    {passing.time}{passing.exceptionIdentifier}
                </div>
            );
        });
    }

    renderExceptionList(scheduleInfo) {
        return (
            <div className="schedules-schedule-exception-info-list">
                {
                    scheduleInfo.exceptions.map(exception =>
                        <div key={exception}>
                            {exception}
                        </div>
                    )
                }
            </div>
        );
    }

    render() {
        return (
            <div className="schedules-panel">
                {this.renderDropdowns()}
                {this.renderScheduleInfo()}
            </div>
        );
    }
}
