import React, { Component } from 'react';
import { NavMenu } from './nav/NavMenu';
import { Row, Col, Container } from 'react-bootstrap';
import { Footer } from './Footer';
import { Brand } from './Brand';

export class Layout extends Component {
    displayName = Layout.name

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false
        };
    }

    ToggleMenuHandler() {
        const { isMenuOpen } = this.state;

        this.setState({
            isMenuOpen: !isMenuOpen
        });
    }

    render() {
        const { isMenuOpen } = this.state;

        return (
            <div>
                <Container fluid>
                    <Row className="no-padding">
                        <Col className="no-padding">
                            <NavMenu isMenuOpen={isMenuOpen} toggleMenuHandler={() => this.ToggleMenuHandler()} />
                            <div className={`layout-content ${isMenuOpen ? "menu-open" : ""}`}>
                                {this.props.children}
                                <Footer />
                            </div>
                            <Brand />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
