import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useMap, useMapEvents, Marker, Tooltip } from 'react-leaflet';
import { SearchBar } from '../SearchBar';
import { StopCommand } from '../commands/StopCommand';
import { stopIcon, selectedStopIcon } from '../leaflet/StopIcons';
import { Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { RecenterButton } from '../RecenterButton';

export class StopPassingInfo extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        recenter: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "stopPassingInfo";
        this.state = {
            commands: {
                stops: new StopCommand()
            },
            nearStops: [],
            selectedStop: undefined,
            isLoadingPassings: false,
            isLoadingSchedules: false,
            isSchedulesDocumentsClicked: false,
            hasDocuments: false,
            isToRecenter: true
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getNearStops();
        } else {
            state.isToRecenter = true;
            this.setState(state, () => this.getStopTimes());
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        setState(this.stateKey, this.state);
    }

    setMapCenter(center) {
        const { setMapCenter } = this.context;
        setMapCenter([center.lat, center.lng], () => this.getNearStops());
    }

    //NEAR STOPS
    getNearStops() {
        const { commands } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.stops.getNearStops(mapCenter[0], mapCenter[1], 1500, (r) => this.getNearStopsSuccessCallback(r))
    }

    getNearStopsSuccessCallback(result) {
        this.setState({
            nearStops: result
        });
    }
    //----------

    //STOP TIMES
    getStopTimes() {
        this.setState({
            isToRecenter: false,
            isLoadingPassings: true
        });

        const { selectedStop, commands } = this.state;
        if (undefined !== selectedStop) {
            commands.stops.getStopTimes(selectedStop.provider, selectedStop.id, (r) => this.getStopTimesSuccessCallback(r));
        }
    }

    //----------

    //STOP SCHEDULES
    getStopSchedulesDocuments() {
        this.setState({
            isLoadingSchedules: true
        });

        const { selectedStop, commands } = this.state;
        if (undefined !== selectedStop) {
            commands.stops.getStopSchedulesDocuments(selectedStop.provider, selectedStop.code, (r) => this.getStopSchedulesDocumentsSuccessCallback(r));
        }
    }

    getStopSchedulesDocumentsSuccessCallback(result) {
        const { selectedStop } = this.state;
        selectedStop.schedules = result;

        this.setState({
            selectedStop: selectedStop,
            isLoadingSchedules: false,
            hasDocuments: result && result.length,
            isSchedulesDocumentsClicked: false
        });
    }

    closeSchedulesDocuments = () => {
        this.setState({ isSchedulesDocumentsClicked: false });
    };

    openSchedulesDocuments = () => {
        this.setState({ isSchedulesDocumentsClicked: true });
    };

    getStopTimesSuccessCallback(result) {
        const { selectedStop } = this.state;
        selectedStop.passings = result;

        this.setState({
            selectedStop: selectedStop,
            isLoadingPassings: false
        });
    }

    //Helper Functions
    handleStopSelection(stop, recenter) {
        const { setMapCenter } = this.context;
        if (recenter) {
            setMapCenter([stop.coordX, stop.coordY], () => this.getNearStops());
        }

        this.setState({
            selectedStop: stop,
            isToRecenter: recenter
        }, () => {
            //if stop, get stop times
            if (1 === stop.type) {
                this.getStopTimes();
                this.getStopSchedulesDocuments();
            }
        });
    }

    handleSearchClear() {
        this.setState({
            selectedStop: undefined
        });
    }

    handlePassingSelection(passing) {
        const { onSelectPassing } = this.props;
        const { selectedStop } = this.state;
        onSelectPassing({ passing: passing, provider: selectedStop.provider });
    }

    recenter() {
        const { recenter } = this.context;
        this.setState({
            isToRecenter: true
        }, () => {
            recenter(() => this.getNearStops())
        });
    }

    determineIcon(stop) {
        const { selectedStop } = this.state;

        if (undefined !== selectedStop && selectedStop.id === stop.id) {
            return selectedStopIcon;
        }

        return stopIcon;
    }

    formatDuration(duration) {
        if (duration <= 60) {
            return `${duration}min`;
        }

        let h = Math.floor(duration / 60);
        let m = duration % 60;
        m = m < 10 ? '0' + m : m;
        return `${h}h ${m}min`;
    }
    //--------------

    renderPassingInfos() {
        const { selectedStop, isLoadingPassings } = this.state;

        //If not loading, but selected stop or selected stop passings are undefined, return nothing
        if (!isLoadingPassings && (undefined === selectedStop || undefined === selectedStop.passings)) {
            return null;
        }

        return (
            <div className="passing-info-panel">
                <div className="passing-info-panel-header d-flex justify-content-between">
                    <div className="passing-info-panel-header-provider-info">
                        {this.renderProviderLogo(selectedStop)}
                        <div className="d-flex-inline text-truncate">
                            <b>{selectedStop.name}</b> ({selectedStop.code})
                        </div>
                    </div>
                    <div>
                        <div className="icon-refresh schedules-documents-button" onClick={() => this.getStopTimes()} />
                        {this.state.hasDocuments ? (
                            <div className="icon-schedules-documents schedules-documents-button" onClick={() => this.openSchedulesDocuments()} />
                        ) : null}
                    </div>
                </div>
                <ListGroup className="passings-list">
                    {this.renderPassingsList()}
                </ListGroup>
            </div>
        );
    }


    renderSchedulesDocuments() {
        const { selectedStop, isLoadingSchedules, isSchedulesDocumentsClicked } = this.state;

        if (false === isSchedulesDocumentsClicked || !isLoadingSchedules && (undefined === selectedStop || undefined === selectedStop.passings || undefined == selectedStop.schedules)) {
            return null;
        }
        return (
            <div className="schedules-documents-panel" >
                <div className="schedules-documents-panel-header d-flex justify-content-between">
                    <div className="schedules-documents-panel-header-provider-info">
                        <div className="d-flex-inline text-truncate">
                            <b>Schedules</b>
                        </div>
                    </div>
                    <div className="icon-error close-button" onClick={() => this.closeSchedulesDocuments()} />
                </div>
                <ListGroup className="schedules-list">
                    {this.renderSchedulesList()}
                </ListGroup>
            </div>
        );
    }

    renderSchedulesList() {
        const { selectedStop, isLoadingSchedules } = this.state;

        if (isLoadingSchedules) {
            return (
                <ListGroupItem key="schedules-list-spinner" className="text-align-center">
                    <Spinner animation="border" role="status" />
                </ListGroupItem>
            );
        }
        if (0 !== selectedStop.schedules.length) {
            return (
                selectedStop.schedules.map((schedule, index) =>
                    <ListGroupItem key={`schedule-doc-${index}`} >
                        <Row>
                            <Col>
                                  <h6 className="schedule-document-title">
                                    {strings.scheduleDocumentTitle} {schedule.lineCode}
                                  </h6>
                                  {schedule.pdfLink !== "" ? (
                                    <a
                                      className="schedule-document"
                                      href={"https://move-me.mobi/content/MoveMeSchedules/" + schedule.pdfLink}
                                      target="_blank">
                                      {schedule.imageLink !== "" ? (
                                        <img
                                          className="schedule-document-img"
                                          src={"https://move-me.mobi/content/MoveMeSchedules/" + schedule.imageLink}
                                          alt="Schedule preview"/>
                                      ) : (
                                        <a className="schedule-document-link">
                                          <span className="icon-schedules-documents"></span>
                                          {strings.showScheduleDocument} {schedule.lineCode}
                                        </a>
                                      )}
                                    </a>
                                  ) : (
                                    <img
                                      className="schedule-document-img"
                                      src={"https://move-me.mobi/content/MoveMeSchedules/" + schedule.imageLink}
                                      alt="schedule preview"/>
                                  )}
                            </Col>
                        </Row>
                    </ListGroupItem>
                )
            );
        }
    }

    renderPassingsList() {
        const { selectedStop, isLoadingPassings } = this.state;

        if (isLoadingPassings) {
            return (
                <ListGroupItem key="stop-passing-spinner" className="text-align-center">
                    <Spinner animation="border" role="status" />
                </ListGroupItem>
            );
        }

        if (0 !== selectedStop.passings.length) {
            return (
                selectedStop.passings.map((passing, index) =>
                    <ListGroupItem key={`passing-${index}`} onClick={() => this.handlePassingSelection(passing)}>
                        <Row>
                            <Col xs={3} sm={3}>
                                <b>{passing.lineCode}</b>
                            </Col>
                            <Col xs={6} sm={7} className="d-flex align-items-center passings-list-destination-info-col">
                                {passing.destination}
                            </Col>
                            <Col xs={3} sm={2} className={passing.isRT ? "rt-passing-info d-flex align-items-center white-space-no-wrap" : "d-flex align-items-center white-space-no-wrap"}>
                                {this.formatDuration(passing.duration)}
                            </Col>
                        </Row>
                    </ListGroupItem>
                )
            );
        } else {
            return (
                <ListGroupItem key={`passing-no-info}`}>
                    <Row>
                        <Col sm={12}>
                            {strings.noPassingsToShow}
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }
    }
    //----------

    renderProviderLogo(stop, height = "auto") {
        const { getLogo } = this.context;
        const imgUrl = getLogo(stop.provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img className="margin-right-5" height={height} src={imgUrl} alt={`${stop.provider}`} />
        );
    }

    render() {
        const { isToRecenter, nearStops, selectedStop } = this.state;
        const { getMapCenter } = this.context;

        return (
            <div className="stop-passing-info">
                <div className="stop-passing-info-panel">
                    <div className="stop-passing-info-search-bar-panel">
                        <div className="search-bar-and-button">
                            <SearchBar
                                className="stop-passing-info-search-bar"
                                resultsClassName="next-departures-search-bar-results-list"
                                caller="nextdepartures" placeholder={strings.searchPlaceholder}
                                onSelect={(stop, recenter) => this.handleStopSelection(stop, recenter)}
                                onSearchClear={() => this.handleSearchClear()}
                                isRequired={false} />
                            <div className="recenter-button-search">
                                <RecenterButton className="margin-left-5" recenter={() => this.recenter()} />
                            </div>
                        </div>
                        <div className="passings-container">
                            {undefined !== selectedStop ? this.renderPassingInfos() : null}
                            {this.renderSchedulesDocuments()}
                        </div>
                    </div>
                </div>

                <MapContainer className="map-container" center={getMapCenter()} zoom={16} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                    <Dragged setCenter={(center) => this.setMapCenter(center)} />
                    <SetViewOnClick coords={getMapCenter()} isToRecenter={isToRecenter} />
                    {nearStops.map((stop) =>
                        <Marker
                            key={`stop-${stop.code}${stop.provider}`}
                            position={[stop.coordX, stop.coordY]}
                            icon={this.determineIcon(stop)}
                            eventHandlers={{
                                click: () => {
                                    this.handleStopSelection(stop, false)
                                }
                            }}>

                            <Tooltip>
                                {this.renderProviderLogo(stop, "15px")}
                                <strong>{stop.name}</strong>
                                ({stop.code})
                            </Tooltip>
                        </Marker>
                    )}
                </MapContainer>
            </div>
        );
    }
}

function SetViewOnClick({ coords, isToRecenter }) {
    const map = useMap();
    if (isToRecenter) {
        map.setView(coords, map.getZoom());
    }
    return null;
}

function Dragged(args) {
    useMapEvents({
        dragend: (e) => {
            args.setCenter(e.target.getCenter());
        }
    });
    return null;
}
