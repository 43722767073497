import React, { Component, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { MapContainer, TileLayer, Marker, Tooltip, Pane, Polyline, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { RecenterButton } from '../RecenterButton';
import { SearchBar } from '../SearchBar';
import { PlanRouteCommand } from '../commands/PlanRouteCommand';
import { Button, Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { SwitchDirectionButton } from '../SwitchDirectionButton';
import { ProviderCommand } from '../commands/ProviderCommand';
import { StopCommand } from '../commands/StopCommand';
import { routeDestinationIcon, routeOriginIcon, stopIcon } from '../leaflet/StopIcons';
import DatePicker from "../libs/react-modern-calendar-datepicker/src/DatePicker";
import "../libs/react-modern-calendar-datepicker/src/DatePicker.css";
import TimeKeeper from 'react-timekeeper';

export class AllRoutesView extends Component {
    static contextTypes = {
        setState: PropTypes.func,
        getState: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        getLogo: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "allRoutesView";
        this.originSearchBarRef = createRef();
        this.destinationSearchBarRef = createRef();

        this.state = {
            commands: {
                stops: new StopCommand(),
                planRoute: new PlanRouteCommand(),
                provider: new ProviderCommand()
            },
            request: {
                startTime: undefined,
                endTime: undefined,
                type: 0, //RequestType 0 == RouteFinder
                route: [{ in: undefined, out: undefined }],
                preferences: undefined, //Preferences 0 == OptimiseArrivalTime, 1 == OptimiseChanges, 4 == OptimiseWalkTime
                maxResultTrips: undefined,
                isArrivalTime: true,
                providers: [],
                walkSpeed: 1,
                maximumRadius: 1000,
                username: "Website",
                ip: "",
                ticket: -1
            },
            nearStops: [],
            trips: [],
            polylines: [],
            timeFilterSelection: undefined,
            displayResults: false,
            planningRoutes: false,
            selectedDepartureDate: null,
            selectedDepartureTime: null,
            selectedArrivalDate: null,
            selectedArrivalTime: null,
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: false,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: false
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getNearStops();
            this.getProviders();
        } else {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        setState(this.stateKey, this.state);
    }

    //DATA

    getNearStops() {
        const { commands } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.stops.getNearStops(mapCenter[0], mapCenter[1], 1500, (r) => this.getNearStopsSuccessCallback(r))
    }

    getNearStopsSuccessCallback(result) {
        this.setState({
            nearStops: result
        });
    }

    getProviders() {
        const { commands } = this.state;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));
    }

    providersSuccessCallback(result) {
        const { request } = this.state;

        request.providers = result.map((provider) => provider.name);
        request.preferences = 0;
        request.maxResultTrips = 3;

        this.setState({
            request: request
        });
    }

    planRoute(e) {
        e.preventDefault();
        const { commands, request } = this.state;

        if (undefined !== request.route[0].in && undefined !== request.route[0].out) {

            this.setState({
                planningRoutes: true,
                polylines: []
            });

            commands.planRoute.calculateRoute(request, (r) => this.planRouteSuccessCallback(r));
        }
    }

    planRouteSuccessCallback(result) {
        this.setState({
            trips: result.trips,
            planningRoutes: false,
            displayResults: true
        }, () => this.renderResultsPaths(result.trips));
    }

    //FUNCTIONS

    setMapCenter(center) {
        const { setMapCenter } = this.context;
        setMapCenter([center.lat, center.lng], () => this.getNearStops());
    }

    getBounds() {
        const { trips, polylines, displayResults } = this.state;
        if (displayResults && 0 !== trips.length && 0 !== polylines) {
            const bounds = trips.reduce((previousValue, currentValue) => {

                if (previousValue.minLatitude > currentValue.minLatitude) {
                    previousValue.minLatitude = currentValue.minLatitude;
                }

                if (previousValue.maxLatitude < currentValue.maxLatitude) {
                    previousValue.maxLatitude = currentValue.maxLatitude;
                }

                if (previousValue.minLongitude > currentValue.minLongitude) {
                    previousValue.minLongitude = currentValue.minLongitude;
                }

                if (previousValue.maxLongitude < currentValue.maxLongitude) {
                    previousValue.maxLongitude = currentValue.maxLongitude;
                }

                return previousValue;
            }, {
                minLatitude: 90.0, //Max possilbe latitude value
                maxLatitude: -90.0, //Min possible latitude value
                minLongitude: 180.0, //Max possible longitude value
                maxLongitude: -180.0 //Min possilbe longitude value
            });

            return [{ lat: bounds.minLatitude, lng: bounds.minLongitude - 0.01 }, { lat: bounds.maxLatitude + 0.005, lng: bounds.maxLongitude }];
        }
        return [{ lat: 41.1483096, lng: -8.6108148 }, { lat: 41.1442942, lng: -8.6105935 }];
    }

    handleStopSelection(stop, recenter) {
        const { setMapCenter } = this.context;
        const { request } = this.state;

        setMapCenter([stop.coordX, stop.coordY], () => this.getNearStops());

        //If both are set or unset, we make the selected stop the origin
        if ((undefined === request.route[0].in && undefined === request.route[0].out) || (undefined !== request.route[0].in && undefined !== request.route[0].out)) {
            request.route[0].in = stop;
            this.originSearchBarRef.current.updateSearchTextWithoutSearching(stop.name);
        } else if (request.route[0].in === undefined) {
            request.route[0].in = stop;
            this.originSearchBarRef.current.updateSearchTextWithoutSearching(stop.name);
        } else {
            request.route[0].out = stop;
            this.destinationSearchBarRef.current.updateSearchTextWithoutSearching(stop.name);
        }

        this.setState({
            request: request
        }, () => {
            this.renderRoutePath();
        });
    }

    handleSelectStartDateOptionClick() {
        this.setState({
            displayArrivalDateDatePicker: false,
            displayDepartureDateDatePicker: true
        }, () => {
            //Simulate click to open calendar right away
            document.getElementsByName("plan-route-departure-date-picker-input")[0].click();
        });
    }

    handleSelectEndDateOptionClick() {
        this.setState({
            displayDepartureDateDatePicker: false,
            displayArrivalDateDatePicker: true
        }, () => {
            //Simulate click to open calendar right away
            document.getElementsByName("plan-route-arrival-date-picker-input")[0].click();
        });
    }

    handleStartStopSelection(stop) {
        const { request } = this.state;
        request.route[0].in = stop;
        this.setMapCenter({ lat: stop.coordX, lng: stop.coordY });

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () => {
            this.renderRoutePath();
        });
    }

    handleStartSearchBarClear() {
        const { request } = this.state;
        request.route[0].in = undefined;

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        });
    }

    handleEndStopSelection(stop) {
        const { request } = this.state;

        request.route[0].out = stop;
        this.setMapCenter({ lat: stop.coordX, lng: stop.coordY });

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () => {
            this.renderRoutePath();
        });
    }

    handleEndSearchBarClear() {
        const { request } = this.state;
        request.route[0].out = undefined;

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        });
    }

    determineStopIcon(stop) {
        const { request } = this.state;

        if (undefined !== request.route[0].in && stop.code === request.route[0].in.code) {
            return routeOriginIcon;
        }

        if (undefined !== request.route[0].out && stop.code === request.route[0].out.code) {
            return routeDestinationIcon;
        }

        return stopIcon;
    }

    determineTripIcon(trip) {
        if (trip.subTrips.length === 1 && trip.subTrips[0].isWalking) {
            return "icons/walking.png";
        } else {
            return "icons/bus.png";
        }
    }

    determineLineCode(trip) {
        const subtrip = trip.subTrips.find(st => undefined !== st && null !== st.lineCode && undefined !== st.lineCode);
        if (undefined !== subtrip) {
            return subtrip.lineCode;
        }

        return "";
    }

    switchDirections() {
        const { request } = this.state;
        const origin = request.route[0].in;
        const destination = request.route[0].out;

        //Reset all
        request.route[0].in = undefined;
        request.route[0].out = undefined;
        this.originSearchBarRef.current.updateSearchTextWithoutSearching("");
        this.destinationSearchBarRef.current.updateSearchTextWithoutSearching("");
        //----

        if (undefined === origin && undefined === destination) {
            return null;
        }

        if (undefined !== origin) {
            request.route[0].out = origin;
            this.destinationSearchBarRef.current.updateSearchTextWithoutSearching(origin.name);
        }

        if (undefined !== destination) {
            request.route[0].in = destination;
            this.originSearchBarRef.current.updateSearchTextWithoutSearching(destination.name);
        }

        this.setState({
            request: request,
            trips: [],
            polylines: [],
            displayResults: false
        }, () =>
            this.renderRoutePath()
        );
    }

    setStartDate(date) {
        const { request } = this.state;

        request.startTime = date;
        request.endTime = undefined;

        this.setState({
            request: request,
            timeFilterSelection: "Agora",
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: false,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: false
        });
    }

    handleSelectedDepartureDate(date) {
        this.setState({
            selectedDepartureDate: date,
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: true
        });
    }

    handleSelectedDepartureTime(time) {
        const { request, selectedDepartureDate } = this.state;

        const newDateObject = new Date(selectedDepartureDate.year, selectedDepartureDate.month - 1, selectedDepartureDate.day, time.hour, time.minute);
        request.startTime = newDateObject;
        request.endTime = undefined;

        this.setState({
            selectedDepartureTime: { hour: time.hour, minute: time.minute },
            request: request,
            displayDepartureDateDatePicker: false,
            displayDepartureDateTimePicker: false,
            timeFilterSelection: "Partida"
        });
    }

    handleSelectedArrivalDate(date) {
        this.setState({
            selectedArrivalDate: date,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: true
        });
    }

    handleSelectedArrivalTime(time) {
        const { request, selectedArrivalDate } = this.state;

        const newDateObject = new Date(selectedArrivalDate.year, selectedArrivalDate.month - 1, selectedArrivalDate.day, time.hour, time.minute);
        request.endTime = newDateObject;
        request.startTime = undefined;

        this.setState({
            selectedArrivalTime: { hour: time.hour, minute: time.minute },
            request: request,
            displayArrivalDateDatePicker: false,
            displayArrivalDateTimePicker: false,
            timeFilterSelection: "Chegada"
        });
    }

    determineSelectedTime() {
        const { request, timeFilterSelection } = this.state;

        switch (timeFilterSelection) {
            case "Agora":
                return strings.planRouteTimeFilterSelectNow;
            case "Partida":
                return `${strings.planRouteTimeFilterSelectedStartDate} ${request.startTime.toLocaleString(strings.locale)}`;
            case "Chegada":
                return `${strings.planRouteTimeFilterSelectedEndDate} ${request.endTime.toLocaleString(strings.locale)}`;
            default:
                return strings.planRouteTimeFilterSelectNow;
        }
    }

    determineTimePickerLabel(time) {
        if (null === time) {
            const now = new Date();
            return {
                hour: now.getHours(),
                minute: now.getMinutes()
            }
        }

        return time;
    }

    //RENDER FUNCTIONS
    renderPlanRouteInfo() {
        return (
            <form className="plan-route-form-panel" onSubmit={(e) => this.planRoute(e)}>
                <div className="plan-route-form-panel-img">
                    <img className="margin-right-5 align-self-center" src="icons/planroute_start_stop_icon.png" alt="" height="75px" />
                </div>

                {this.renderStopSelectionPanel()}
                {this.renderFiltersPanel()}

                <div className="plan-route-plan-route-button-panel">
                    <Button type="submit">{strings.planRoute}</Button>
                </div>
            </form>
        );
    }

    renderStopSelectionPanel() {
        return (
            <div className="plan-route-stop-selection-panel">
                <div className="d-flex">
                    <div className="plan-route-info-search-bar-panel" style={{ marginRight: "45px" }}>
                        <SearchBar
                            ref={this.originSearchBarRef}
                            className="plan-route-info-search-bar"
                            resultsClassName="plan-route-search-bar-results-list"
                            caller="planroute-startstop"
                            placeholder={strings.originStopPlaceHolder}
                            onSelect={(stop) => this.handleStartStopSelection(stop)}
                            onSearchClear={() => this.handleStartSearchBarClear()}
                            isRequired={true} />
                    </div>
                    {/*<RecenterButton className="margin-left-5" /*recenter={() => this.recenter() />*/}
                </div>

                <div className="d-flex">
                    <div className="plan-route-info-search-bar-panel">
                        <SearchBar
                            ref={this.destinationSearchBarRef}
                            className="plan-route-info-search-bar"
                            resultsClassName="plan-route-search-bar-results-list"
                            caller="planroute-endstop"
                            placeholder={strings.destinationStopPlaceHolder}
                            onSelect={(stop) => this.handleEndStopSelection(stop)}
                            onSearchClear={() => this.handleEndSearchBarClear()}
                            isRequired={true} />
                    </div>
                    <SwitchDirectionButton className="margin-left-5" switchDirections={() => this.switchDirections()} />
                </div>
            </div>
        );
    }

    renderFiltersPanel() {
        return (
            <div className="plan-route-filters-panel">
                <div className="plan-route-filters-panel-dropdowns" style={{ justifyContent: "end" }}>
                    <div className="dropdown">
                        <Button variant="secondary" className="dropdown-toggle plan-route-filter-button" id="timeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex">
                                <div className="icon-clock" />
                                {this.determineSelectedTime()}
                            </div>
                        </Button>
                        <ul className="dropdown-menu width-100" aria-labelledby="timeDropdown">
                            <li onClick={() => this.setStartDate(new Date())}>
                                <a className="dropdown-item">{strings.planRouteTimeFilterSelectNow}</a>
                            </li>
                            <li onClick={() => this.handleSelectStartDateOptionClick()}>
                                <a className="dropdown-item">{strings.planRouteTimeFilterSelectStartDate}</a>
                            </li>
                            <li onClick={() => this.handleSelectEndDateOptionClick()}>
                                <a className="dropdown-item">{strings.planRouteTimeFilterSelectEndDate}</a>
                            </li>
                        </ul>
                    </div>

                    {/*
                    <div className="dropdown">
                        <Button variant="secondary" className="dropdown-toggle plan-route-filter-button" id="filtersDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="d-flex">
                                <div className="icon-filter" />
                                {strings.filter}
                            </div>
                        </Button>
                        <ul className="dropdown-menu width-100" aria-labelledby="filtersDropdown">
                            <li><a className="dropdown-item" href="#">Filtros</a></li>
                        </ul>
                        </div>
                    */}
                </div>

                {this.renderDepartureDateTimePicker()}
                {this.renderArrivalDateTimePicker()}
            </div>
        );
    }

    renderDepartureDateTimePicker() {
        const { selectedDepartureDate, selectedDepartureTime, displayDepartureDateDatePicker, displayDepartureDateTimePicker } = this.state;

        if (displayDepartureDateDatePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <DatePicker
                        value={selectedDepartureDate}
                        locale={strings.locale}
                        onChange={(e) => this.handleSelectedDepartureDate(e)}
                        colorPrimary="#3167a4"
                        inputPlaceholder={strings.planRouteTimeFilterSelectStartDate}
                        inputName="plan-route-departure-date-picker-input"
                        inputClassName="plan-route-date-picker-input"
                        shouldHighlightWeekends
                    />
                </div>
            );
        }

        if (displayDepartureDateTimePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <TimeKeeper
                        time={this.determineTimePickerLabel(selectedDepartureTime)}
                        hour24Mode
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect
                        forceCoarseMinutes
                        onDoneClick={(time) => this.handleSelectedDepartureTime(time)}
                        doneButton={(time) => (
                            <div className="plan-route-time-picker-done-button" onClick={() => this.handleSelectedDepartureTime(time)}>
                                {strings.done}
                            </div>
                        )}
                    />
                </div>
            );
        }

        return null;
    }

    renderArrivalDateTimePicker() {
        const { selectedArrivalDate, selectedArrivalTime, displayArrivalDateDatePicker, displayArrivalDateTimePicker } = this.state;

        if (displayArrivalDateDatePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <DatePicker
                        value={selectedArrivalDate}
                        locale={strings.locale}
                        onChange={(e) => this.handleSelectedArrivalDate(e)}
                        colorPrimary="#3167a4"
                        inputPlaceholder={strings.planRouteTimeFilterSelectEndDate}
                        inputName="plan-route-arrival-date-picker-input"
                        inputClassName="plan-route-date-picker-input"
                        shouldHighlightWeekends
                    />
                </div>
            );
        }

        if (displayArrivalDateTimePicker) {
            return (
                <div className="plan-route-filters-select-date-panel">
                    <TimeKeeper
                        time={this.determineTimePickerLabel(selectedArrivalTime)}
                        hour24Mode
                        switchToMinuteOnHourSelect
                        closeOnMinuteSelect
                        forceCoarseMinutes
                        onDoneClick={(time) => this.handleSelectedArrivalTime(time)}
                        doneButton={(time) => (
                            <div className="plan-route-time-picker-done-button" onClick={() => this.handleSelectedArrivalTime(time)}>
                                {strings.done}
                            </div>
                        )}
                    />
                </div>
            );
        }

        return null;
    }

    renderTrips() {
        const { trips, planningRoutes, displayResults } = this.state;

        //If not loading, but selected stop or selected stop passings are undefined, return nothing
        if (!displayResults) {
            return null;
        }

        return (
            <div className="plan-route-trips-panel">
                <div className="plan-route-trips-panel-header d-flex justify-content-between">
                    <div className="d-flex-inline text-truncate">
                        <b>{strings.plannedRoutes}</b>
                    </div>
                    <div className="icon-refresh2 refresh-button" onClick={(e) => this.planRoute(e)} />
                </div>

                <ListGroup className="trips-list">
                    {
                        planningRoutes ?
                            <ListGroupItem key="plan-route-spinner" className="text-align-center">
                                <Spinner animation="border" role="status" />
                            </ListGroupItem>
                            :
                            this.renderTripsInfo(trips)}
                </ListGroup>

            </div>
        );
    }

    renderTripsInfo(trips) {
        const { onSelectRoute } = this.props;

        if (0 !== trips.length) {
            return (
                trips.map((trip, index) =>
                    <ListGroupItem key={`trip-${index}`} onClick={() => onSelectRoute({ trip: trip })}>
                        <Row>
                            <Col xs={2} sm={2}>
                                <img src={this.determineTripIcon(trip)} alt="" height="30px" width="30px" />
                            </Col>
                            <Col xs={7} sm={8} className="plan-route-trips-list-trip-header-route-info">
                                <div className="d-flex-inline text-truncate">
                                    <b>{this.determineLineCode(trip)}</b> {trip.endPlace.name}
                                </div>
                                {this.renderTripChanges(trip)}
                            </Col>
                            <Col xs={3} sm={2} className="plan-route-trips-list-trip-header-duration-info">
                                <div>
                                    {trip.departureTimeToShow}
                                </div>
                                <div>
                                    {trip.arrivalTimeToShow}
                                </div>
                            </Col>
                        </Row>
                    </ListGroupItem>
                )
            );
        } else {
            return (
                <ListGroupItem key={`trip-no-info}`}>
                    <Row>
                        <Col sm={12}>
                            {strings.noInfoToShow}
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }
    }

    renderTripChanges(trip) {
        return (
            <div className="plan-route-trips-list-trip-header-changes-info">
                <img src="icons/prOrigin.png" alt="" width="10px" height="10px" />
                <div className="icon-menu" />

                {trip.subTrips.map((subtrip, index) => this.renderSubtripChangesInfo(trip, subtrip, index))}

                <div className="icon-menu" />
                <img src="icons/prDestination.png" alt="" width="10px" height="10px" />
            </div>
        );
    }

    renderSubtripChangesInfo(trip, subtrip, index) {
        const { getLogo } = this.context;
        const components = [];

        if (subtrip.isWalking) {
            components.push(<img key={`subtrip-${index}-walking`} src="icons/walking.png" alt="" width="20px" height="20px" />);
        } else {
            //TO DO: Use transport type here instead of bus as default
            components.push(
                <div key={`subtrip-${index}-provider-change`} className="display-contents">
                    <img key={`subtrip-${index}-bus`} src="icons/bus.png" alt="" width="20px" height="20px" />
                    <img key={`subtrip-${index}-provider-logo`} src={getLogo(subtrip.provider)} alt="" width="15px" height="15px" />
                </div>
            );
        }

        if (trip.subTrips.length - 1 !== index) {
            components.push(<div key={`subtrip-${index}-next-change`} className="icon-menu" />);
        }

        return components;
    }

    renderRoutePath() {
        const { request } = this.state;

        if (undefined !== request.route[0].in && undefined !== request.route[0].out) {
            const positions = [
                [request.route[0].in.coordX, request.route[0].in.coordY],
                [request.route[0].out.coordX, request.route[0].out.coordY]
            ];


            const polyline = [<Polyline key={`polyline-${positions[0][0]}-${positions[1][1]}`} positions={positions} color="#f26839" />];
            this.setState({
                polylines: polyline
            });
        }
    }

    renderResultsPaths(trips) {
        let tripsPolylines = [];
        if (0 !== trips.length) {
            tripsPolylines = trips.slice().reverse().map((trip, index) => {
                const positions = [];

                trip.subTrips.forEach(subtrip =>
                    subtrip.passings.forEach(passing =>
                        positions.push([passing.x, passing.y])
                    )
                );
                return <Polyline key={`polyline-${index}`} positions={positions} color={(trips.length - 1) === index ? "#f26839" : "gray"} />
            })

            this.setState({
                polylines: tripsPolylines
            });
        }
    }

    renderNearStops() {
        const { displayResults, nearStops } = this.state;

        if (displayResults) {
            return null;
        }

        return (
            nearStops.map((stop) =>
                <Marker
                    key={`stop-${stop.code}${stop.provider}`}
                    position={[stop.coordX, stop.coordY]}
                    icon={this.determineStopIcon(stop)}
                    eventHandlers={{
                        click: () => {
                            this.handleStopSelection(stop, false)
                        }
                    }}>

                    <Tooltip>
                        {this.renderProviderLogo(stop, "15px")}
                        <strong>{stop.name}</strong>
                        ({stop.code})
                    </Tooltip>
                </Marker>
            )
        );
    }

    renderProviderLogo(stop, height = "auto") {
        const { getLogo } = this.context;
        const imgUrl = getLogo(stop.provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img className="margin-right-5" height={height} src={imgUrl} alt={`${stop.provider}`} />
        );
    }

    render() {
        const { getMapCenter } = this.context;
        const { request, polylines, displayResults } = this.state;

        return (
            <div className="plan-route-info">

                <div className="plan-route-info-panel align-items-center">
                    {this.renderPlanRouteInfo()}

                    {this.renderTrips()}
                </div>

                {/*
                    displayArrivalDateTimePicker ?
                        <div className="plan-route-filters-select-end-date-panel">
                            <DatePicker
                                value={request.endTime}
                                locale={strings.locale}
                                onChange={(e) => this.handleSelectedArrivalDate(e)}
                                inputPlaceholder={strings.planRouteTimeFilterSelectEndDate}
                                colorPrimary="#3167a4"
                                inputClassName="more-schedules-date-picker-input"
                                shouldHighlightWeekends
                            />
                        </div>
                        :
                        null
                */}

                <MapContainer className="map-container" center={getMapCenter()} bounds={this.getBounds()} zoom={16} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                    {this.renderNearStops()}

                    {
                        undefined !== request.route[0].in ?
                            <Marker
                                key={`route-origin`}
                                position={[request.route[0].in.coordX, request.route[0].in.coordY]}
                                icon={routeOriginIcon}
                            >

                                <Tooltip>
                                    {this.renderProviderLogo(request.route[0].in, "15px")}
                                    <strong>{request.route[0].in.name}</strong>
                                    ({request.route[0].in.code})
                                </Tooltip>
                            </Marker>
                            :
                            null
                    }

                    {
                        undefined !== request.route[0].out ?
                            <Marker
                                key={`route-destination`}
                                position={[request.route[0].out.coordX, request.route[0].out.coordY]}
                                icon={routeDestinationIcon}
                            >

                                <Tooltip>
                                    {this.renderProviderLogo(request.route[0].out, "15px")}
                                    <strong>{request.route[0].out.name}</strong>
                                    ({request.route[0].out.code})
                                </Tooltip>
                            </Marker>
                            :
                            null
                    }
                    <Pane>
                        {polylines}
                    </Pane>

                    {
                        displayResults ?
                            <SetBounds bounds={this.getBounds()} />
                            :
                            null
                    }
                    <Dragged setCenter={(center) => this.setMapCenter(center)} />
                    <SetViewOnClick coords={getMapCenter()} />
                </MapContainer>
            </div>
        );
    }
}

function SetViewOnClick({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
}

function SetBounds({ bounds }) {
    const map = useMap();
    map.fitBounds(bounds);
    return null;
}

function Dragged(args) {
    useMapEvents({
        dragend: (e) => {
            args.setCenter(e.target.getCenter());
        }
    });
    return null;
}