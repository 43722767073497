import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        nextDepartures: "Next Departures",
        options: "Options",
        planRoute: "Plan Route",
        schedules: "Schedules",
        scheduleDocumentTitle: "Schedule for line",
        about: "About",
        searchPlaceholder: "Select a stop",
        originStopPlaceHolder: "Departure",
        destinationStopPlaceHolder: "Arrival",
        plannedRoutes: "Trip Options",
        includes: "Includes",
        changes: "change(s)",
        walkAround: "Walk around",
        estimatedWaitingTime: "Waiting period between",
        arrivalEstimatedAt: "Arrival estimated at",
        and: "and",
        or: "or",
        duration: "Duration",
        userLocationPlace: "Your location",
        fares: "Andante Fares",
        title: "Ticket",
        mustIncludeZones: "Must include zone(s)",
        bus: "Bus",
        train: "Train",
        subway: "Subway",
        OptimiseArrivalTime: "Fastest",
        OptimiseChanges: "Less changes",
        OptimiseWalkTime: "Less walking",
        planRouteTimeFilterSelectNow: "Now",
        planRouteTimeFilterSelectStartDate: "Select departure date",
        planRouteTimeFilterSelectedStartDate: "Departure at",
        planRouteTimeFilterSelectEndDate: "Select arrival date",
        planRouteTimeFilterSelectedEndDate: "Arrival at",
        optionsButtonTooltip: "More options",
        switchDirectionsButtonTooltip: "Reverse direction",
        userLocationButtonTooltip: "Use your location",
        lineInfoStopsTitle: "Stops",
        lineInfoMoreScheds: "more schedules",
        showStops: "Show stops",
        showSchedule: "Show schedule",
        showScheduleDocument: "Show schedule for line",
        showTrip: "Show route",
        showMap: "Show map",
        moreSchedsTitle: "Schedules",
        noPassingsToShow: "No passings to show",
        noTripsToShow: "Could not find travel options",
        noInfoToShow: "No information to show",
        noFaresToShow: "Route not covered by Andante",
        noSchedulesToShow: "There are no schedules to show",
        ocasionalFares: "Occasional Titles",
        subscriptionFares: "Monthly Subscription Titles",
        seeFaresDiscount: "Check the Andante pricing table to learn about discount subscription",
        selectDate: "Select date",
        provider: "Provider",
        line: "Line",
        direction: "Direction",
        directionGo: "Go",
        directionReturn: "Return",
        directionCircular: "Circular",
        daytype: "Day Type",
        locale: "en",
        hoursFilter: "Filter by hours",
        initialHourFilter: "Start time",
        finalHourFilter: "End time",
        filter: "Filter",
        close: "Close",
        done: "Done",
        copyright: "Copyright 2021 OPT",
        allRightsReserved: "All rights reserved",
        privacyPolicy: "Privacy Policy",
        pageNotFound: "Page not found",
        zone: "Zone"
    },
    pt: {
        nextDepartures: "Próximas Partidas",
        planRoute: "Planear Rota",
        options: "Opções",
        schedules: "Horários",
        scheduleDocumentTitle: "Horário linha",
        about: "Acerca de",
        searchPlaceholder: "Selecione uma paragem",
        originStopPlaceHolder: "Partida",
        destinationStopPlaceHolder: "Chegada",
        plannedRoutes: "Opções de Viagem",
        includes: "Inclui",
        changes: "transbordo(s)",
        walkAround: "Ir a pé cerca de",
        estimatedWaitingTime: "Período de espera entre",
        arrivalEstimatedAt: "Chegada estimada às",
        and: "e",
        or: "ou",
        duration: "Duração",
        userLocationPlace: "A sua localização",
        fares: "Tarifários Andante",
        title: "Título",
        mustIncludeZones: "Deve incluir a(s) zona(s)",
        bus: "Autocarro",
        train: "Comboio",
        subway: "Metro",
        OptimiseArrivalTime: "Mais rápido",
        OptimiseChanges: "Menos transbordos",
        OptimiseWalkTime: "Menos percurso a pé",
        planRouteTimeFilterSelectNow: "Agora",
        planRouteTimeFilterSelectStartDate: "Escolher data de partida",
        planRouteTimeFilterSelectedStartDate: "Partida às",
        planRouteTimeFilterSelectEndDate: "Escolher data de chegada",
        planRouteTimeFilterSelectedEndDate: "Chegada às",
        optionsButtonTooltip: "Mais opções",
        switchDirectionsButtonTooltip: "Inverter sentido",
        userLocationButtonTooltip: "Utilizar a sua localização",
        lineInfoStopsTitle: "Paragens",
        lineInfoMoreScheds: "mais horários",
        showStops: "Ver paragens",
        showSchedule: "Ver horário",
        showScheduleDocument: "Ver horário para a linha",
        showTrip: "Ver viagem",
        showMap: "Ver mapa",
        moreSchedsTitle: "Horários",
        noPassingsToShow: "Sem tempos de passagem",
        noTripsToShow: "Não foi possível encontrar opções de viagem",
        noInfoToShow: "Sem informação para mostrar",
        noFaresToShow: "Trajeto não abrangido pelo Andante",
        noSchedulesToShow: "Sem horários para mostrar",
        ocasionalFares: "Títulos Ocasionais",
        subscriptionFares: "Títulos de Assinatura Mensal",
        seeFaresDiscount: "Consulte o tarifário Andante para conhecer as assinaturas com desconto",
        selectDate: "Selecione uma data",
        provider: "Operador",
        line: "Linha",
        direction: "Sentido",
        directionGo: "Ida",
        directionReturn: "Volta",
        directionCircular: "Circular",
        daytype: "Tipo de Dia",
        locale: "pt",
        hoursFilter: "Filtrar por horas",
        initialHourFilter: "Hora inicial",
        finalHourFilter: "Hora final",
        filter: "Filtrar",
        close: "Fechar",
        done: "Concluído",
        copyright: "Copyright 2021 OPT",
        allRightsReserved: "Todos os direitos reservados",
        privacyPolicy: "Política de Privacidade",
        pageNotFound: "Página não encontrada",
        zone: "Zona"
    }
});
