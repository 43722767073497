import L from 'leaflet';

const stopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/stopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const lineInfoStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const lineInfoSelectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoSelectedStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const routeOriginIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeOriginIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

const routeDestinationIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeDestinationIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

export { stopIcon, selectedStopIcon, lineInfoStopIcon, lineInfoSelectedStopIcon, routeOriginIcon, routeDestinationIcon };
