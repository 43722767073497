import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { strings } from '../resources/strings.js';

export class Footer extends Component {

    render() {
        return (
            <div>
                <Container fluid>
                    <div className="footer">
                        <p>
                            <a href="https://www.opt.pt" target="_blank" rel="noopener noreferrer">{strings.copyright}</a>. {strings.allRightsReserved}. <a href="#">{strings.privacyPolicy}</a>
                        </p>
                    </div>
                </Container>
            </div>
        )
    }
}