import { StopAPI } from '../../api/StopAPI';

export class StopCommand {
    getNearStops(latitude, longitude, radius, successCallback, errorCallback) {
        const apiResult = StopAPI.getNearStops(latitude, longitude, radius);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getStopTimes(provider, stopId, successCallback, errorCallback) {
        const apiResult = StopAPI.getStopTimes(provider, stopId);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }

    getStopSchedulesDocuments(provider, stopCode, successCallback, errorCallback) {
        const apiResult = StopAPI.getStopSchedulesDocuments(provider, stopCode);
        apiResult.then((result) => {
            if (undefined !== successCallback) {
                successCallback(JSON.parse(result));
            }
        }).catch((err) => {
            if (undefined !== errorCallback) {
                errorCallback(err.message);
            }
        });
    }
}
