import React, { Component } from 'react';
import { MenuItem } from './MenuItem';
import './NavMenu.css';
import { strings } from "../../resources/strings.js";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { isMenuOpen } = this.props;

        return (
            <nav className={isMenuOpen ? "icon-nav-open" : "icon-nav"}>
                <div className="menu-background-header" />
                <div className="menu-background" />
                <div className="icon-nav-item">
                    <div className="menu-toggle">
                        <div onClick={this.props.toggleMenuHandler}>
                            <div className={"icon-menu-1"}>
                                <img width={isMenuOpen ? "150px" : "0px"} src="icons/moveme_logo.png" alt="MoveMe3 Web" />
                            </div>
                        </div>
                    </div>

                    <MenuItem key="menu-next-departures" label={strings.nextDepartures} icon="icon-zones" to="/next-departures" />
                    <MenuItem key="menu-plan-route" label={strings.planRoute} icon="icon-routeplan" to="/plan-route" />
                    <MenuItem key="menu-schedules" label={strings.schedules} icon="icon-clock" to="/schedules" />
                    <MenuItem key="menu-about" label={strings.about} icon="icon-info" to="/about" />
                </div>
            </nav>
        );
    }
}
